/* You can add global styles to this file, and also import other style files */
@import 'assets/plugins/bootstrap/css/bootstrap.css';
@import'assets/css/style.default.css';

html, body { height: 100%; }
body { margin: 0; font-family: 'Manrope'}
h1, h2, h3, h4, h5, h6 { margin: 0; font-family: 'Manrope'}
/* .w-100{width: 100%!important;} */
.custom-warn{
  background-color: rgb(245, 172, 36) !important;
  color:  #fff !important;
}
.custom-success{
  background-color: rgb(86, 196, 23) !important;
  color: #fff !important;
}
.custom-info{
  background-color: rgb(100, 204, 252)!important;
  color: #fff !important;
}
 .custom-error{
  background-color:rgb(247, 86, 58) !important;
  color: #fff !important;
}
.mat-mdc-snack-bar-action button {
  max-height: 36px;
  min-width: 0;
  color:  #fff !important;
}
td, th{
  white-space: nowrap !important;
}
.mtc-header{
  font-size: 1.2rem !important;
}
p{
  margin: 0 !important;
}
/* ngxInput */

.iti {
	display: block !important;
	margin-bottom: 20px;
  width: 100%;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
  max-width: 260px;
  min-width: 256px;
  font-size: 12px;
}

.iti .iti__country-list {
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	width: 244px;
	max-height: 170px;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	font-size: 14px;
	border-color: #c7cace;
	border-radius: 0;
	padding: 5px 10px;
}

.iti .search-container input:focus {
	outline: none;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

ngx-intl-tel-input input {
	height: 44px;
	margin-bottom: 20px;
	padding: 10px;
	border-style: solid;
  	border-width: 0px;
	border-bottom-width: 1px;
	border:1px solid #ced4da;
	border-radius: 4px;
	font-size: 14px;
  	max-width: 100%;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
	border-color: #f44336;
}

ngx-intl-tel-input input:hover {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
	outline: none !important;
	border-bottom:2px solid #3f51b5;
	box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
  font-size: 18px;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
  font-size: 18px;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
  font-size: 18px;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
  font-size: 18px;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}
.user_name{
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 125px;
}
.img-profile {
    object-fit: cover;
    width: 30px;
    height: 30px;
}
/* hode eye icon in input type password */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
ul.appselec{
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 14px;
}
ul.appselec li{
	padding: 10px 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}
ul.appselec li:hover{
	background: #efefef;
	cursor: pointer;
}
ul.appselec li mat-icon{
	width: 20px;
}

.app-selection .mat-mdc-menu-content{
	min-width: 274px;
	max-height: 400px;
}
